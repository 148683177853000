import { defineStore } from 'pinia';
import { ref } from 'vue';
import { dicomUpload, originVisualization, applyVisualizationOptions, prediction } from '../api';

interface FileDataState {
  files: File[];
  uploadProgress: number;
  showPredictRes: boolean;
  initData: InitData;
  dicomInfo: DicomInfo;
  predictionData: PredictionData;
  predictBtnActive: boolean;
  loading: boolean;
}

interface InitData {
  imageUrl: string;
  window_center: number;
  window_width: number;
  image_weight: number;
  tip_threshold: number;
  tube_threshold: number;
}

interface DicomInfo {
  imageUrl: string;
  img_max: number;
  img_min: number;
  window_center: number;
  window_width: number;
}

interface PredictionData {
  imageUrl: string;
  complete_score: number;
  incomplete_score: number;
  image_weight: number;
  tip_threshold: number;
  tube_threshold: number;
}

export const useDicomStore = defineStore('dicom', {
  state: (): FileDataState => ({
    files: [],
    uploadProgress: 0,
    showPredictRes: false,
    initData: {
      imageUrl: "",
      window_center: 0,
      window_width: 0,
      image_weight: 0,
      tip_threshold: 0,
      tube_threshold: 0
    },
    dicomInfo: {
      imageUrl: "",
      img_max: 0,
      img_min: 0,
      window_center: 0,
      window_width: 0
    },
    predictionData: {
      imageUrl: "",
      complete_score: 0,
      incomplete_score: 0,
      image_weight: 0,
      tip_threshold: 0,
      tube_threshold: 0
    },
    predictBtnActive: false,
    loading: true
  }),
  actions: {
    setFile(file: File[]) {
      this.files = file;
    },
    async dicomUpload() {
      const dicomData = await dicomUpload((progress) => {
        this.uploadProgress = progress;
      });

      if (dicomData) {
        this.dicomInfo = dicomData as DicomInfo;
        this.initData.imageUrl = dicomData.imageUrl
        this.initData.window_center = dicomData.window_center
        this.initData.window_width = dicomData.window_width
        this.initData.imageUrl = dicomData.imageUrl

        this.predictBtnActive = true;
      }
    },
    async setShowPredictRes(show: boolean) {
      this.showPredictRes = show;
      const predictionData = await prediction();
      if (predictionData) {
        this.predictionData = predictionData as PredictionData;
        this.initData.image_weight = predictionData.image_weight
        this.initData.tip_threshold = predictionData.tip_threshold
        this.initData.tube_threshold = predictionData.tube_threshold

        this.loading = false;
      }
    },
    initFileDataState() {
      this.files = [];
      this.uploadProgress = 0;
      this.showPredictRes= false;
      this.dicomInfo = {
        imageUrl: "",
        img_max: 0,
        img_min: 0,
        window_center: 0,
        window_width: 0
      };
      this.predictionData = {
        imageUrl: "",
        complete_score: 0,
        incomplete_score: 0,
        image_weight: 0,
        tip_threshold: 0,
        tube_threshold: 0
      };
      this.predictBtnActive = false;
      this.uploadProgress = 0;
      this.loading = true;
    },
    initOption() {
      const initDicomInfo = {
        imageUrl: this.initData.imageUrl,
        img_max: this.dicomInfo.img_max,
        img_min: this.dicomInfo.img_min,
        window_center: this.initData.window_center,
        window_width: this.initData.window_width
      }
      const initPredictionData = {
        imageUrl: "",
        complete_score: this.predictionData.complete_score,
        incomplete_score: this.predictionData.incomplete_score,
        image_weight: this.initData.image_weight,
        tip_threshold: this.initData.tip_threshold,
        tube_threshold: this.initData.tube_threshold
      }
      this.dicomInfo = initDicomInfo;
      this.predictionData = initPredictionData;
    },
    initPredictImgUrl() {
      this.predictionData.imageUrl = "";
    },
    async applyWindowOption(option: any) {
      const res = await originVisualization(option);
      if(res) {
        return res;
      }
    },
    async applyDicomOption(option: any) {
      const res = await applyVisualizationOptions(option);
      if(res) {
        return res;
      }
    },
    setImgUrl(windowImgUrl: string, dicomImgUrl: string) {
      if(windowImgUrl != "") {
        this.dicomInfo.imageUrl = windowImgUrl;
      }
      if(dicomImgUrl != "") {
        this.predictionData.imageUrl = dicomImgUrl;
      }
    },
    setLoading(loading: boolean) {
      this.loading = loading;
    }
  },
  getters: {
    getFile(state): File[] {
      return state.files;
    },
    getUploadProgress(state): number {
      return state.uploadProgress;
    },
    getDicomInfo(state): DicomInfo | null {
      return state.dicomInfo;
    },
    getShowPredictRes(state): boolean {
      return state.showPredictRes;
    },
    getPredictionData(state): PredictionData | null {
      return state.predictionData;
    },
    getPredictBtnActiveState(state): boolean {
      return state.predictBtnActive;
    },
    getLoadingState(state): boolean {
      return state.loading;
    }
  },
});
