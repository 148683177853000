
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {

    onMounted(() => {
      //
    })

    return {
    };
  },
});
