import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-921bc83a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view main" }
const _hoisted_2 = { class: "main__left_box" }
const _hoisted_3 = { class: "top_box" }
const _hoisted_4 = { class: "main__right_box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_DicomFileUpload = _resolveComponent("DicomFileUpload")!
  const _component_DicomOption = _resolveComponent("DicomOption")!
  const _component_DicomViewer = _resolveComponent("DicomViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, { class: "logo" }),
        _createElementVNode("button", {
          class: "btn__default blue",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
        }, "Logout")
      ]),
      _withDirectives(_createVNode(_component_DicomFileUpload, null, null, 512), [
        [_vShow, !_ctx.showPredictRes]
      ]),
      _withDirectives(_createVNode(_component_DicomOption, null, null, 512), [
        [_vShow, _ctx.showPredictRes]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_DicomViewer)
    ])
  ]))
}