
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import { useDicomStore } from '@/store/dicom'
import { useUsersStore } from '@/store/users'
import VueSlider from 'vue-slider-component'
import { nextTick } from 'vue';
import 'vue-slider-component/theme/antd.css'

export default defineComponent({
  components: {
    VueSlider
  },
  setup() {
    const dicomStore = useDicomStore();
    const usersStore = useUsersStore();
    const userId = computed(() => usersStore.getLoginUser?.data.user_id);
    const dicomInfo = computed(() => dicomStore.getDicomInfo);
    const predictionData = computed(() => dicomStore.getPredictionData);
    const showPredictRes = computed(() => dicomStore.getShowPredictRes);
    const showLoadingBox = computed(() => dicomStore.getLoadingState);
    const wc = ref(0);
    const ww = ref(0);
    const tip = ref(0.01);
    const tube = ref(0.01);
    const iw = ref(0.01);
    const windowCenter = computed(() => dicomInfo.value?.window_center || 0);
    const windowWidth = computed(() => dicomInfo.value?.window_width || 0);
    const imgMin = computed(() => dicomInfo.value?.img_min || 0);
    const imgMax = computed(() => dicomInfo.value?.img_max || 0);
    const mask = ref(false);
    const heatMap = ref(false); 
    const probability = ref(false); 
    const tipThreshold = computed(() => predictionData.value?.tip_threshold || 0);
    const tubeThreshold = computed(() => predictionData.value?.tube_threshold || 0);
    const imageWeight = computed(() => predictionData.value?.image_weight || 0);
    const comboboxOption = ref([{ name: 'Complete', selected: true }, { name: 'Incomplete', selected: false }]);
    const comboboxSelected = ref('Complete');
    const comboboxOpen = ref(false);
    const windowImgUrl = ref("");
    const dicomImgUrl = ref("");

    const changeWindowOption =  async (): Promise<void> => {
      await nextTick();
      dicomStore.setLoading(true);
      
      const windowRes = await sendWindowOption();
      const dicomRes = ref(true);

      if (mask.value || heatMap.value || probability.value) {
        dicomRes.value = await sendDicomOption();
      }
      
      if (windowRes && dicomRes) {
        dicomStore.setImgUrl(windowImgUrl.value, dicomImgUrl.value);
        dicomStore.setLoading(false);
      }
    }

    const changeDicomOption =  async (): Promise<void> => {
      await nextTick();
      const dicomRes = await sendDicomOption();
      if (dicomRes) {
        dicomStore.setImgUrl("", dicomImgUrl.value);
        dicomStore.setLoading(false);
      }
    }

    const selectOption = (idx: number) => {
      for(let i=0; i<comboboxOption.value.length; i++) {
        const option = comboboxOption.value[i];
        option.selected = false;
        if(idx == i) {
          option.selected = true;
          comboboxSelected.value = option.name;
          changeDicomOption();
        }
      }
    }

    const toggleCombobox = () => {
      comboboxOpen.value = !comboboxOpen.value;
    }

    const sendWindowOption = async (): Promise<boolean> => {
      const option = {
        window_center: Number(wc.value),
        window_width: Number(ww.value),
        user_id: userId.value
      }
      
      const res = await dicomStore.applyWindowOption(option);
      if(res) {
        windowImgUrl.value = res;
      }
      return true;
    }

    const sendDicomOption = async (): Promise<boolean> => {
      if(mask.value || heatMap.value || probability.value) {
        dicomStore.setLoading(true);
        const option = {
          window_center: Number(wc.value),
          window_width: Number(ww.value),
          tube_threshold: Number(tube.value),
          tip_threshold: Number(tip.value),
          image_weight: Number(iw.value),
          mask_check: mask.value,
          heatmap_check: heatMap.value,
          probability_map_check: probability.value,
          heatmap_option: comboboxSelected.value,
          user_id: userId.value
        }

        const res = await dicomStore.applyDicomOption(option);
        if(res) {
          dicomImgUrl.value = res;
        }
        return true;
      } else {
        dicomStore.initPredictImgUrl();
        dicomImgUrl.value = "";
      }
      return false;
    }

    const initOption = () => {
      dicomStore.initOption();
      mask.value = false;
      heatMap.value = false;
      probability.value = false;
      comboboxSelected.value = 'Complete';
      dicomStore.initPredictImgUrl();
      dicomImgUrl.value = "";
    }

    watch(dicomInfo, (newVal) => {
      wc.value = newVal?.window_center ?? 0.01;
      ww.value = newVal?.window_width ?? 0.01;
    });

    watch(predictionData, (newVal) => {
      tip.value = newVal?.tip_threshold ?? 0.01;
      tube.value = newVal?.tube_threshold ?? 0.01;
      iw.value = newVal?.image_weight ?? 0.01;
    });

    watch(showPredictRes, (newVal) => {
      if(!newVal) { // delete 버튼 클릭 시 mask, heat map, probability map 값 초기화
        mask.value = false;
        heatMap.value = false;
        probability.value = false;
      }
    });

    onMounted(() => {
      mask.value = false;
      heatMap.value = false;
      probability.value = false;
    });

    return {
      showPredictRes,
      intMask: [0, 4095],
      floatMask: [0.01, 1],
      windowCenter,
      windowWidth,
      imgMin,
      imgMax,
      tipThreshold,
      tubeThreshold,
      imageWeight,
      mask,
      heatMap,
      probability,
      comboboxOption,
      comboboxSelected,
      comboboxOpen,
      selectOption,
      toggleCombobox,
      dicomInfo,
      sendDicomOption,
      sendWindowOption,
      wc,
      ww,
      tip,
      tube,
      iw,
      predictionData,
      initOption,
      changeWindowOption,
      changeDicomOption,
      showLoadingBox
    };
  },
});
