
import { defineComponent, computed, onMounted } from 'vue';
// import Main from "../components/Main.vue";
import UserInfo from "../components/UserInfo.vue";
import Logo from "../components/Logo.vue";
import DicomFileUpload from "../components/DicomFileUpload.vue";
import DicomOption from "../components/DicomOption.vue";
import DicomViewer from "../components/DicomViewer.vue";
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login'
import { useUsersStore } from '@/store/users'
import { useDicomStore } from '@/store/dicom'

export default defineComponent({
  components: {
    // UserInfo,
    Logo,
    DicomFileUpload,
    DicomOption,
    DicomViewer
  },
  setup() {
    const router = useRouter();
    const dicomStore = useDicomStore();
    const showPredictRes = computed(() => dicomStore.getShowPredictRes);

    const logout = async (): Promise<any> => {
      const res = await useLoginStore().logout();
      if(res === "Success") {
        router.push({ name: 'login' });
        useUsersStore().removeUserData();
        useDicomStore().initFileDataState();
      } else {
        console.log('LOGOUT FAIL');
      }
    }

    onMounted(() => {
      useUsersStore().getUserData();
    });

    return {
      logout,
      showPredictRes
    };
  },
});
