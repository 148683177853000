
import { defineComponent, computed } from 'vue';
// import { useLocaleStore } from '@/store/locale'

export default defineComponent({
  components: {
    // NavBar
  },
  setup() {
    // const localeStore = useLocaleStore();
    // localeStore.getLanguageData();
    // const checkLanguage = computed(() => localeStore.getCheckLanguage);
    
    return {
      // checkLanguage
    };
  },
});
