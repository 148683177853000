import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b001d3cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Login),
      _createVNode(_component_router_link, { to: "/join" }, {
        default: _withCtx(() => [
          _createTextVNode("회원가입")
        ]),
        _: 1
      })
    ])
  ]))
}