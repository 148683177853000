
import { computed, defineComponent, ref, watch } from 'vue';
import { useDicomStore } from '@/store/dicom'

export default defineComponent({
  setup() {
    const isDragOver = ref(false);
    const dicomStore = useDicomStore();
    const dicomFile = computed(() => dicomStore.getFile);
    const fileInput = ref<HTMLInputElement | null>(null); // 파일 입력 요소 참조
    const uploadProgress = computed(() => dicomStore.getUploadProgress);
    const predictBtnActive = computed(() => dicomStore.getPredictBtnActiveState);
    const showPredictRes = computed(() => dicomStore.getShowPredictRes);

    // Drag & Drop 핸들러
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      isDragOver.value = true;
    };
    const handleDragEnter = (event: DragEvent) => {
      event.preventDefault();
    };
    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();
      isDragOver.value = false;
    };

    // 파일 입력창을 클릭하는 함수
    const handleFileClick = () => {
      if (fileInput.value) {
        fileInput.value.click(); // 파일 입력창 클릭
      }
    };

    // 파일 선택 핸들러
    const handleFileInputChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        const files = Array.from(input.files);
        uploadFiles(files);
      }
    };

    // Drop 이벤트 핸들러
    const handleDrop = (event: DragEvent) => {
      event.preventDefault();
      isDragOver.value = false;
      if (event.dataTransfer && event.dataTransfer.files) {
        const files = Array.from(event.dataTransfer.files);
        uploadFiles(files);
      }
    };

    // 파일 업로드 함수
    const uploadFiles = (files: File[]) => {
      dicomStore.setFile(files);
      dicomStore.dicomUpload();
    };

    const dicomPredict = () => {
      if (predictBtnActive.value) {
        dicomStore.setShowPredictRes(true);
      }
    }

    watch(showPredictRes, (newVal) => {
      if(!newVal) { // delete 버튼 클릭 시 fileInput 값 초기화
        if (fileInput.value) {
          fileInput.value.value = ''; // 완전 초기화
        }
      }
    });

    return {
      isDragOver,
      uploadProgress,
      handleFileInputChange,
      handleDragOver,
      handleDragEnter,
      handleDragLeave,
      handleDrop,
      handleFileClick, // 파일 클릭 함수 추가
      fileInput, // 파일 입력 요소 참조
      dicomPredict,
      predictBtnActive,
      dicomFile
    };
  },
});
