import axios, { AxiosProgressEvent } from 'axios';
import { ref } from 'vue';
import { useLoginStore } from './store/login';
import { useUsersStore, JoinUser } from './store/users';
import { useDicomStore } from './store/dicom';

export const PROJECT_TYPE = "TDM";
// export const LOGIN_SERVER_URL = 'http://10.10.20.220/auth';
// export const NGTUBE_SERVER_URL = 'http://10.10.20.233:8000'; 

// 10.10.20.239:80

export const LOGIN_SERVER_URL = '/auth'; // nginx
export const NGTUBE_SERVER_URL = '/api';

/**
 * 언어 데이터를 가져온다.
 * @returns {string} 언어 데이터
 */
// export async function getLanguageData() {
//   try {
//     const response = await axios.get(`${API_BASE_URL}/api/get/language`, {
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching users:', error);
//     return [];
//   }
// }

/**
 * LOGIN SERVER - login
 */
export async function login(userId: string, passwd: string) {
  try {
    const response = await axios.post(`${LOGIN_SERVER_URL}/login`, {
      "user_id": userId,
      "passwd": passwd,
      "login_type": PROJECT_TYPE
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - logout
 */
export async function logout() {
  try {
    const token = useLoginStore().getAccessToken;
    const userId = useUsersStore().getLoginUser.data.user_id;

    const response = await axios.post(`${LOGIN_SERVER_URL}/logout`, {
      "user_id": userId,
      "passwd": sessionStorage.getItem('user_passwd'),
      "login_type": PROJECT_TYPE
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);

    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getAllUserData
 */
export async function getAllUserData() {
  const token = useLoginStore().getAccessToken;
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/getAllUserData`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * LOGIN SERVER - getUserData
 */
export async function getUserData() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getUserData`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - setUserData
 */
export async function setUserData(userData: JoinUser) {
  try {
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/setUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - checkRepetition
 */
export async function checkRepetition(inputId: string) {
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/checkRepetition`, {
      params: {
        "user_id": inputId
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - removeUserData
 */
export async function removeUserData(userData: JoinUser) {
  try {
    const token = useLoginStore().getAccessToken;
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/removeUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - updateUserData
 */
export async function updateUserData(userData: JoinUser) {
  try {
    const token = useLoginStore().getAccessToken;
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/updateUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getGroups
 */
export async function getGroups() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getGroups`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - addGroups
 */
export async function addGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/addGroups`, {
      "group_id": 0,
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - deleteGroups
 */
export async function deleteGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/deleteGroups`, {
      "group_id": 0,
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - updateGroups
 * @param group_id PK. group_id를 기준으로 level, name이 업데이트 됨
 */
export async function updateGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/updateGroups`, {
      "group_id": 0, 
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getSystemLogs
 */
export async function getSystemLogs() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getSystemLogs`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - changePasswd
 * @param user_id 사용자 ID
 * 관리자 계정으로 접속 시 사용할 수 있는 기능
 * 사용자의 비밀번호를 ID와 동일한 값으로 변경한다.
 */
export async function changePasswd(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/changePasswd`, {
      "user_id": sessionStorage.getItem('user_id')
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - checkTokenExpire
 */
export async function checkTokenExpire() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/checkTokenExpire`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - recreateToken
 */
export async function recreateToken() {
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/recreateToken`, {
      params: {
        "user_id": "string",
        "passwd": "string",
        "login_type": PROJECT_TYPE
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * NGTUBE SERVER - initializeUserData
 */
export async function initializeUserData() {
  const userId = useUsersStore().getLoginUser.data.user_id;
  
  try {
    const response = await axios.post(`${NGTUBE_SERVER_URL}/initialize_user_data`, {
        "user_id": userId,
      },{
    });
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}

/**
 * NGTUBE SERVER - dicomUpload
 */
export async function dicomUpload(uploadProgressCallback: (progress: number) => void) {
  const formData = new FormData();
  const dicomFile = useDicomStore().getFile; // Pinia 스토어에서 파일 가져오기
  const userId = useUsersStore().getLoginUser.data.user_id;

  if (!dicomFile || dicomFile.length === 0 || !(dicomFile[0] instanceof File)) {
    console.error("No file selected or invalid file.");
    return;
  }
  formData.append('file', dicomFile[0]);
  formData.append('user_id', userId);

  try {
    const response = await axios.post(`${NGTUBE_SERVER_URL}/dicom_upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          uploadProgressCallback(progress);
        }
      },
    });
    
    const img_min = parseFloat(response.headers['img_min']);
    const img_max = parseFloat(response.headers['img_max']);
    const window_center = parseFloat(response.headers['window_center']);
    const window_width = parseFloat(response.headers['window_width']);
    const blob = new Blob([response.data], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return { imageUrl, img_min, img_max, window_center, window_width };
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}

/**
 * NGTUBE SERVER - prediction
 */
export async function prediction() {
  const dicomFile = useDicomStore().getFile;
  const userId = useUsersStore().getLoginUser.data.user_id;

  try {
    const response = await axios.post(`${NGTUBE_SERVER_URL}/prediction`, {
      file_name: dicomFile[0].name,
      user_id: userId
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const imageUrl = "";
    const complete_score = parseFloat(response.data['complete_score']);
    const image_weight = parseFloat(response.data['image_weight']);
    const incomplete_score = parseFloat(response.data['incomplete_score']);
    const tip_threshold = parseFloat(response.data['tip_threshold']);
    const tube_threshold = parseFloat(response.data['tube_threshold']);

    return { imageUrl, complete_score, image_weight, incomplete_score, tip_threshold, tube_threshold };
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * NGTUBE SERVER - originVisualization
 */
export async function originVisualization(option: any) {
  try {
    const response = await axios.post(`${NGTUBE_SERVER_URL}/origin_visualization`, option, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return imageUrl;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * NGTUBE SERVER - applyVisualizationOptions
 */
export async function applyVisualizationOptions(option: any) {
  try {
    const response = await axios.post(`${NGTUBE_SERVER_URL}/apply_visualization_options`, option, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return imageUrl;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}