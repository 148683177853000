
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import { useDicomStore } from '@/store/dicom'

export default defineComponent({
  components: {
  },
  setup() {
    const dicomStore = useDicomStore();
    const dicomInfo = computed(() => dicomStore.getDicomInfo);
    const predictionData = computed(() => dicomStore.getPredictionData);
    const showPredictRes = computed(() => dicomStore.getShowPredictRes);
    const showOrigin = ref(false);
    const dicomFile = computed(() => dicomStore.getFile);

    const deleteDicomInfo = () => {
      dicomStore.initFileDataState();
    }

    watch(predictionData, (newVal) => {
      // console.log('predictionData >> ', newVal);
    });

    onMounted(() => {
    });

    return {
      dicomInfo,
      showPredictRes,
      showOrigin,
      dicomFile,
      predictionData,
      deleteDicomInfo
    };
  },
});
