
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { checkRepetition, setUserData } from '@/api';
import { useUsersStore, JoinUser } from '@/store/users'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const inputData = ref<JoinUser>({
      "passwd": "1",
      "data": {
        "user_id": "tester",
        "group": {
          "group_id": 1,
          "level": 1,
          "name": "사용자"
        },
        "name": "테스터",
        "number": "010-1234-5678",
        "email": "tester@ziovision.co.kr",
        "division": "소속"
      }
      // "passwd": "",
      // "data": {
      //   "user_id": "",
      //   "group": {
      //     "group_id": 1,
      //     "level": 1,
      //     "name": "사용자"
      //   },
      //   "name": "",
      //   "number": "",
      //   "email": "",
      //   "division": ""
      // }
    });
    const checkRes = ref();
    const message = ref('');
    const duplicateText = {
      empty: "* 아이디를 입력해주세요.",
      valid: "* 사용 가능한 아이디입니다.",
      invalid: "* 이미 사용중인 아이디입니다."
    }

    const setUser = async (): Promise<void> => {
      const res = await setUserData(inputData.value);
      if(res.result === "Success") {
        router.push({name: 'login'});
      }
    }

    const duplicateConfirm = async (): Promise<void> => {
      if(inputData.value.data.user_id != '') {
        const res = await checkRepetition(inputData.value.data.user_id);
        checkRes.value = res.result;
        if(res.result) {
          message.value = duplicateText.valid;
        } else {
          message.value = duplicateText.invalid;
        }
      } else {
        checkRes.value = false;
        message.value = duplicateText.empty;
      }
    }

    const movePage = () => {
      router.push({name: 'login'});
    }

    onMounted(() => {
      //
    })

    return {
      inputData,
      setUser,
      duplicateConfirm,
      message,
      checkRes,
      movePage
    };
  },
});
