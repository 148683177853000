import { computed } from 'vue';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { useLoginStore } from '../store/login';

// 페이지 컴포넌트 임포트
import LoginView from "@/views/LoginView.vue";
import JoinView from "@/views/JoinView.vue";
import MainView from "@/views/MainView.vue";

const routes: Array<RouteRecordRaw> = [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/join',
      name: 'join',
      component: JoinView,
    },
    {
      path: '/',
      name: 'main',
      component: MainView,
    },
    // {
    //   path: "/page",
    //   name: "Page",
    //   component: Page,
    //   children: [
    //     {
    //       path: "subPage",
    //       name: "SubPage",
    //       component: SubPage,
    //     },
    //   ]
    // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loginStore = useLoginStore();
  const accessToken = computed(() => loginStore.getAccessToken);
  
  if(!accessToken.value) {
    if(to.name == "main") {
      next({ name: "login" });
    } 
    else {
      next();
    }
  } 
  else {
    if(to.name == "login") {
      next({ name: "main" });
    } else {
      next();
    }
  }
});

export default router;